import {
  CheckEmailDomainResponse,
  CreateSubscriptionArgs,
  NiceLabelInstancesResponse,
  Subscription,
  SubscriptionsResponse,
  SubscriptionsSearchParams,
  checkUniqueInstanceName,
  UpcomingRenewal,
  DownloadCsvSearchParams,
} from '~/types/subscriptions'
import { ApiKy } from './index'
import { InvoicesResponse } from '~/types/invoices'
import {
  CreditNotesResponse,
  PartnerCreditNotesSearchParams,
} from '~/types/credit-notes'

const { stripEmptyKeys } = useHelpers()

export default class Api extends ApiKy {
  get(searchParams: SubscriptionsSearchParams) {
    const params = stripEmptyKeys(searchParams)
    return this.ky
      .get(this.url, { searchParams: params })
      ?.json<SubscriptionsResponse>()
  }

  getSubscriptionInvoices(
    subscriptionId: string,
    searchParams: SubscriptionsSearchParams,
  ) {
    const params = stripEmptyKeys(searchParams)
    return this.ky
      .get(`${this.url}/${subscriptionId}/invoices`, { searchParams: params })
      ?.json<InvoicesResponse>()
  }

  getSubscriptionCreditNotes(
    subscriptionId: string,
    searchParams: PartnerCreditNotesSearchParams,
  ) {
    const params = stripEmptyKeys(searchParams)
    return this.ky
      .get(`${this.url}/${subscriptionId}/credit-notes`, {
        searchParams: params,
      })
      ?.json<CreditNotesResponse>()
  }

  getSingle(subscriptionId: string) {
    return this.ky.get(`${this.url}/${subscriptionId}`)?.json<Subscription>()
  }

  getNiceLabelInstances(subscriptionId: string) {
    return this.ky
      .get(`${this.url}/${subscriptionId}/nicelabel-instances`)
      ?.json<NiceLabelInstancesResponse>()
  }

  createSubscription<T>(payload: T) {
    return this.ky.post(this.url, { json: payload })?.json<Subscription>()
  }

  update(subscriptionId: string, payload: Partial<CreateSubscriptionArgs>) {
    return this.ky
      .patch(`${this.url}/${subscriptionId}`, { json: payload })
      ?.json<Subscription>()
  }

  activate(subscriptionId: string, payload: Partial<CreateSubscriptionArgs>) {
    return this.ky
      .post(`${this.url}/${subscriptionId}/activate`, { json: payload })
      ?.json<Subscription>()
  }

  extendTrial(subscriptionId: string) {
    return this.ky.post(`${this.url}/${subscriptionId}/extend-trial`)
  }

  cancelSubscription(subscriptionId: string) {
    return this.ky.post(`${this.url}/${subscriptionId}/cancel`)
  }

  checkEmailDomain(emailDomain: string) {
    return this.ky
      .post(`${this.url}/check-email-domain`, {
        json: { emailDomain },
      })
      ?.json<CheckEmailDomainResponse>()
  }

  checkUniqueInstanceName(instanceName: string) {
    return this.ky
      .post(`${this.url}/unique-instance-name`, {
        json: { instanceName },
      })
      ?.json<checkUniqueInstanceName>()
  }

  adjustPrinters(
    subscriptionId: string,
    numberOfPrinters: number,
    productOrderNumber: string,
  ) {
    return this.ky.post(`${this.url}/${subscriptionId}/adjust-printers`, {
      json: { numberOfPrinters, productOrderNumber },
    })
  }

  getUpcomingRenewals(partnerId: string) {
    return this.ky
      .get(`${this.url}/upcoming-renewals`, {
        searchParams: { partnerId },
      })
      ?.json<UpcomingRenewal[]>()
  }

  downloadCsv(searchParams: DownloadCsvSearchParams) {
    const params = stripEmptyKeys(searchParams)
    return this.ky.get(`${this.url}/download-csv`, {
      searchParams: params,
    })
  }

  resendInvites(subscriptionId: string, instanceId: string) {
    return this.ky.post(
      `${this.url}/${subscriptionId}/nicelabel-instances/${instanceId}/resend-invites`,
    )
  }

  addInviteEmails(subscriptionId: string, instanceId: string, emails: string) {
    return this.ky.post(
      `${this.url}/${subscriptionId}/nicelabel-instances/${instanceId}/add-invite-emails`,
      { json: { emails } },
    )
  }
}
